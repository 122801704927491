// polyfils can now be included, but they should be removed from sitefinity template
// import '@progress-wad/site-polyfills/build/polyfills.js';

import './modules/dataloader.js';
import './modules/support-css-property.js';
import './modules/section-banner.js';
import './modules/section-patch.js';
import './modules/scroll-anchors.js';
import './modules/scroll-to-form.js';
import './modules/sharer.js';
import './modules/toggle.js';
import './modules/tabs.js';
import './modules/site-specific.js';
import './modules/replacement-query-parameter.js';
import './modules/quiz.js';
import './modules/randomized-slider.js';
import './modules/local-date-time.js';
import '@progress-wad/litebox';
import '@progress-wad/siema-slider';
import '@progress-wad/sticky-element';
import '@progress-wad/site-search/build/index.es6.js';
import '@progress-wad/site-search/build/init.es6.js';
import '@progress-wad/youtube-lite-embed/dist/index.mjs';
import { MultiSelect } from '@progress-wad/multi-select';

// dom ready
document.addEventListener('DOMContentLoaded', () => {
  MultiSelect.init();
});
