import { scrollToItem } from './scroll-to-item.js';

(() => {
  const tabContent = document.querySelectorAll('.TabsContent .TabsContent-item')
  const tabNav = document.querySelector('.TabsNav')
  const tabBtns = document.querySelectorAll('.TabsNav > a')
  const activeClass = 'is-open'

  function loadMobileTabs() {
    let ind = 0
    tabBtns.forEach(tab => {
      let tabMobContent = '<button type="button" data-id=' + tab.getAttribute('data-id') + ' class="'+ tab.classList + '">' + tab.textContent + '</button>'
      tabContent[ind].insertAdjacentHTML('afterbegin', tabMobContent)
      ind++
    })
  }

  function initTabHash() {
    if (window.location.hash && tabNav) {
      const sel = window.location.hash.toString().replace('#', '')
      const newActiveEl = document.querySelector('[data-id="' + sel + '"]')

      if (newActiveEl)
        newActiveEl.click()
    }
  }

  function updateTabs(e) {
    e.preventDefault()

    let el = e.target
    let scrollable = e.target.scrollParam
    let attr = el.getAttribute('data-id')

    if (el.classList.contains(activeClass))
      return -1

    let ind = (el.tagName == "A") ? [].indexOf.call(el.parentElement.children, el) : [].indexOf.call(el.parentNode.parentElement.children, el.parentNode)

    tabBtns.forEach( tab => {
      tab.classList.remove(activeClass);
      tab.removeAttribute('aria-label');
    })

    tabBtns[ind].classList.add(activeClass)
    tabBtns[ind].setAttribute('aria-label', 'selected')

    tabContent.forEach( item => item.classList.remove(activeClass) )
    tabContent[ind].classList.add(activeClass)

    tabBtnsMob.forEach( tabM => tabM.classList.remove(activeClass) )
    tabContent[ind].querySelector('button:not(.Btn)').classList.add(activeClass)

    if (scrollable == true) {
      scrollToItem(tabContent[ind])
    }

    if (tabNav.getAttribute('data-tabs-hash') === 'true') {
      window.location.hash = el.getAttribute('data-id')
    }
  }

  loadMobileTabs()
  const tabBtnsMob = document.querySelectorAll('.TabsContent button:not(.Btn)')

  tabBtns.forEach(tab => {
    tab.addEventListener('click', updateTabs)
  })

  tabBtnsMob.forEach(tabM => {
    tabM.addEventListener('click', updateTabs)
    tabM.scrollParam = true
  })

  initTabHash()

})();
