/**
 * time.js--datetime
 *
 * iso dates to local date time string converter
 * format time element iso date to local datetime string
 *
 * ## examples
 * <time datetime="@startDateISO" data-format="MMMM D, YYYY" class="js--datetime"> ... </time>
 * <time datetime="@startDateISO" data-format="MMMM D, YYYY, HH:mm a" class="js--datetime"> ... LOCAL DATETIME STRING WILL BE INSERTED HERE BY JS ... </time>
 *
 * ## results
 * <time datetime="2020-10-01T07:45:00.0000000Z" data-format="MMMM D, YYYY" class="js--datetime">October 1, 2020</time>
 * <time datetime="2020-09-01T07:45:00.0000000Z" data-format="MMMM D, YYYY, HH:mm a" class="js--datetime">September 1, 2020, 10:45 am</time>
 *
 * ## example
 * <time datetime="2020-09-01T07:45:00.0000000Z" data-format="... ANY FORMAT YOU WANT HERE ..." class="js--datetime"> ... TO BE FILLED with JS ... </time>
 *
 * ## docs
 * For more on format look here => https://day.js.org/docs/en/display/format
 *
 * ## usage on telerik.com
 * https://www.telerik.com/webinars/fiddler
 */

import dayjs from 'dayjs';
import { interactive } from './interactive.js';

// fancy DOM ready
interactive().then(() => {
  const defaultFormat = 'MMMM D, YYYY, HH:mm a';
  const timeElements = Array.from(document.querySelectorAll('time.js--datetime'));

  // render/display formatted local datetime in <time>...</time> element
  timeElements.forEach(element => {
    // time "datetime" el attr => datetime ISO string
    // example => datetime="2020-10-01T07:45:00.0000000Z"
    const isoDateStr = element.dateTime;

    // time dataset format string (data-format)
    // example => data-format="MMMM D, YYYY, HH:mm a"
    const dataFormatStr = element.dataset.format;

    // format the iso date to local datetime using the specified format via data attr
    // example result => "September 1, 2020, 10:45 am"
    const formatedLocalDateStr = dayjs(isoDateStr).format(dataFormatStr || defaultFormat);

    // update text of the time element
    element.innerText = formatedLocalDateStr;
  });
});
