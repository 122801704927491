import SocialShare from '@progress-wad/social-share';

(() => {
  const socialLinks = Array.from(document.querySelectorAll('[class*="js-social-"]'));

  socialLinks.forEach(element => {
    const classList = element.classList + ' ';
    const media = classList.split('js-social-')[1].split(' ')[0];
    const url = element.hasAttribute('data-article-link') ? document.location.host + element.getAttribute('data-article-link') : null;
    const text = element.getAttribute('data-article-title');

    new SocialShare({
      element,
      media,
      ...url ? { url } : {},
      ...text ? { text } : {}
    });
  });
})();
