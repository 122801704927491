// required class for forms
(() => {
  document.querySelectorAll('input[required], select[required], textarea[required]').forEach(function(e) {
    if (e.previousElementSibling) e.previousElementSibling.classList.add('required')
  });
})();

// detect ie
(function detectIE() {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE ');
  const trident = ua.indexOf('Trident/');

  if (msie > 0 || trident > 0) {
    document.body.classList.add('t-ie');
  }
})();
