import { scrollToItem } from './scroll-to-item.js';

(() => {
  let triggers = document.querySelectorAll('[data-sf-role="form-container"]:not(.js-scroll-to-error-off) button')

  triggers.forEach(trigger => {
      trigger.addEventListener('click', e => {
      let el = e.target
      let closestForm = el.closest('.FormHolder') || el.closest('[data-sf-role="form-container"]')
      scrollToItem(closestForm)
    });
  })

})();
