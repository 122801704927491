import $ from 'jquery';

(() => {
  const $anchorLinks = $("a[href^='#']:not([href='#'])").not('.litebox, .js-noanchor, .js-noanchor a');
  let target = [];
  let hash = '';
  const $page = $('html, body');

  if (!$anchorLinks.length) {
    return;
  }

  $anchorLinks.on('click', function onClick(e) {
    const navHeight = (!!document.querySelector('.Nav')) ? document.querySelector('.Nav').getBoundingClientRect().height : 0;
    hash = this.getAttribute('href');

    if (hash.length >= 2) {
      target = $(hash).length ? $(hash) : $(`[name='${hash.slice(1)}']`);
    }

    if (target.length) {
      $page.not(':animated').animate({ scrollTop: (target.offset().top - navHeight) }, 500);
      e.preventDefault();
    }
  });
})();
