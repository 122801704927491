import $ from 'jquery';
import { DataAttrParser } from '@progress-wad/data-attr-parser';

/**
 * Init prgs/tlrk jquery plugins from data attrs
 *
 * @param {String} selector - data attr name - example "data-tlrk-plugin" or "data-prgs-plugin"
 */
function init(selector) {
  const elements = Array.from(document.querySelectorAll(`[${selector}]`));

  elements.forEach(element => {
    const $element = $(element);
    const plugins = element.getAttribute(selector).split(' ');

    plugins.forEach(plugin => {
      if ($.isFunction($.fn[plugin])) {
        const group = plugin.toLowerCase();
        const options = DataAttrParser.parse(element.dataset, [group]);
        $element[plugin](options[group]);
      }
    });
  });
}

$(document).ready(() => {
  // init('data-tlrk-plugin');
  // init('data-prgs-plugin');
  // init('data-ipswitch-plugin');
  init('data-chef-plugin');
});
