(() => {
  let randomizedSlider = document.querySelector('.Slider--randomized')

  if (randomizedSlider) {
    let slides = document.querySelectorAll('.Slider--randomized > div')
    let slidesCount = 5
    let chosen = []

    while (chosen.length < slidesCount) {
      let rand = Math.floor(Math.random() * slides.length)
      if (chosen.indexOf(rand) === -1) chosen.push(rand);
    }

    slides.forEach( (slide, currentIndex) => {
      if ( !chosen.includes(currentIndex)) {
        slide.remove()
      }
    })

    randomizedSlider.classList.remove('-dn')
  }
})();
