(() => {
  let qaStr = '?qa='

  if (window.location.search.indexOf(qaStr) == 0) {
    const sel = window.location.search.replace(qaStr, '.js-qa-')
    const el = document.querySelector(sel)
    if (el)
      el.classList.remove('-dn')
  }
})();
