export function scrollToItem(el) {
  if (el) {
    window.setTimeout( () => {
      let nav = document.querySelector('.Nav')
      let navHeight = (nav) ? nav.getBoundingClientRect().height : 0
      let scrollToPos = window.scrollY + el.getBoundingClientRect().top - navHeight

      window.scrollTo({
        top: scrollToPos,
        behavior: 'smooth'
      })
    }, 500)
  }
}
